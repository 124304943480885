import { ClubApp } from '@aaa/emember/types';
import { membershipOffers as membershipOffersHoosier } from './membership-offers.hoosier';
import { membershipOffers as membershipOffersNorthampton } from './membership-offers.northampton';
import { membershipOffers as membershipOffersSouthJersey } from './membership-offers.southjersey';
import { membershipOffers as membershipOffersNorthway } from './membership-offers.northway';
import { membershipOffers as membershipOffersShelby } from './membership-offers.shelby';
import { membershipOffers as membershipOffersMidstates } from './membership-offers.midstates';

export const membershipOffers = {
  [ClubApp.Hoosier]: membershipOffersHoosier,
  [ClubApp.MidStates]: membershipOffersMidstates,
  [ClubApp.Shelby]: membershipOffersShelby,
  [ClubApp.Northampton]: membershipOffersNorthampton,
  [ClubApp.Colorado]: membershipOffersNorthampton,
  [ClubApp.Minneapolis]: membershipOffersNorthampton,
  [ClubApp.Northway]: membershipOffersNorthway,
  [ClubApp.SouthJersey]: membershipOffersSouthJersey,
  [ClubApp.Empty]: membershipOffersNorthampton,
};
