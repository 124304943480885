import { ClubOption } from '../../../types/club-option';

export const firstName = { required: 'Please use letters only.', isNotString: 'Please use letters only.' };
export const lastName = { required: 'Please use letters only.', isNotString: 'Please use letters only.' };
export const address1 = { required: 'Invalid Address' };
export const promoCode = { invalidPromoCode: 'Invalid Promo Code' };
export const city = { required: 'Invalid City' };
export const state = { required: 'Invalid State' };
export const birthday = {
  required: 'Please enter your birthdate MM/DD/YYYY',
  invalidDateFormat: 'Please enter your birthdate MM/DD/YYYY',
};
export const phone = {
  required: 'Please enter a valid phone number. Phone numbers must have 10 digits.',
  invalidPhoneNumber: 'Please enter a valid phone number. Phone numbers must have 10 digits.',
};

// 610-258-2371
export const email = (option: ClubOption) => {
  return {
    required: 'Invalid Email Address',
    invalidEmail: 'Invalid Email Address',
    emailExists: `This email address is already connected to an existing account. <br> Please call <a href="tel:${option.phoneNumber}">${option.phoneNumberView}</a> for support`,
    lookupFailed: 'Unable to check availability',
    unableToVerify: 'Unable to check availability',
  };
};
export const password = {
  required:
    'Password must include: 8-20 characters, a lowercase letter, an uppercase letter, a number, and a special character (!@#$%^&-_=+?)',
  weakPassword:
    'Password must include: 8-20 characters, a lowercase letter, an uppercase letter, a number, and a special character (!@#$%^&-_=+?)',
};
export const zipcode = (option: ClubOption) => ({
  required: 'Invalid Zip Code',
  invalidZipCode: 'Invalid Zip Code',
  zipcodeIsNotInsideState:
    'This ZIP Code isn’t inside the AAA ' +
    option.name +
    ' region. Please check your entry and try again. <a href="https://www.aaa.com/stop">Find a different AAA club >></a>',
  zipcodeIsNotInsideClub:
    'This ZIP Code isn’t inside the AAA ' +
    option.name +
    ' region. Please check your entry and try again. <a href="https://www.aaa.com/stop">Find a different AAA club >></a>',
});
export const cardNumber = {
  invalid: 'Please enter a valid credit card number',
  unsupportedCard: 'Not allowed credit card number',
  required: 'Please enter a valid credit card number',
};
export const cardCvv = {
  invalid: 'Please enter 3-digit credit card verification code',
  required: 'Please enter 3-digit credit card verification code',
};

export const expMonth = { required: 'Required', min: 'Invalid month' };
export const expYear = { required: 'Required', min: 'Invalid year' };
