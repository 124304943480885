import { createFeatureSelector, createSelector } from '@ngrx/store';
import { MembershipState } from './membership.models';
import { getClubOptions } from '../types/types';
import { getRouterQueryParams } from '../router/router.selectors';
import { RequestStatus } from '../../../types/request-status';
import { AccountStatus, ClubApp } from '@aaa/emember/types';
import { northamptonMedicalPlanText } from '../../modules/share/constants/medical-plan-text';
import { northamptonMedicalPlanFullDescription } from '../../modules/share/constants/medical-plan-full-description';
import { getErrorMessage } from '../generic-errors';
import { getZipcodeDescription } from '../../modules/share/constants/zipcode-description';
import { cardTypes } from '../../constants/card-types';
import { CardFormat } from '@aaa/interface-joinRenew-membership-membershipConnectSuite';
import { PaymentType } from '@aaa/emember/store-payment';

export const MEMBERSHIP_FEATURE_KEY = 'membership';

export const getMembershipState = createFeatureSelector<MembershipState>(MEMBERSHIP_FEATURE_KEY);
export const isMembershipRequestStatus = createSelector(getMembershipState, ({ requestStatus }) => requestStatus);
export const isLoadingMemberShip = createSelector(
  isMembershipRequestStatus,
  (status) => status === RequestStatus.RUNNING,
);
export const isMembershipInitialized = createSelector(
  getMembershipState,
  ({ initializeStatus }) => initializeStatus === RequestStatus.SUCCESS,
);
export const getMembershipError = createSelector(getMembershipState, ({ membershipError }) => membershipError);
export const getClubId = createSelector(getMembershipState, ({ clubId }) => clubId);
export const getZipcode = createSelector(getMembershipState, ({ zipcode }) => zipcode);
export const getClubOption = createSelector(getClubId, getZipcode, (clubId, zipcode) =>
  getClubOptions(clubId, zipcode),
);
export const getMembershipNumber = createSelector(getMembershipState, ({ membershipNumber }) => membershipNumber || '');
export const getMembershipCodes = createSelector(getMembershipState, ({ membershipCodes, membershipCodesMap }) =>
  membershipCodes.map((m) => membershipCodesMap[m]),
);
export const getMembershipValidationMessageError = createSelector(
  getMembershipError,
  getClubOption,
  (validationError, option) => getErrorMessage(validationError, option),
);
export const getTermsAndConditionUrl = createSelector(getClubOption, (option) => option.termsAndConditionUrl);
export const getPrivacyUrl = createSelector(getClubOption, (option) => option.privacyUrl);
export const getMembershipCards = createSelector(getMembershipState, ({ membershipCards }) => membershipCards);

export const getAuthAccountDetails = createSelector(getMembershipState, ({ accountDetails }) => {
  if (
    accountDetails &&
    accountDetails.status !== AccountStatus.GUEST &&
    accountDetails.status !== AccountStatus.UNKNOWN
  ) {
    return accountDetails;
  }

  return null;
});

export const getMembershipCardByLevel = (membershipLabel: string) =>
  createSelector(getMembershipCards, (membershipCards) => {
    const isEqualString = (arg1: string, arg2: string) => arg1.toLowerCase() === arg2.toLowerCase();
    const membershipCard = membershipCards.find((m) => isEqualString(m.membershipLabel, String(membershipLabel)));

    return membershipCard || null;
  });

export const getShowPrivacyPolicyAndTermAndCondition = createSelector(getClubOption, (option) =>
  [ClubApp.Hoosier, ClubApp.MidStates, ClubApp.Northampton, ClubApp.SouthJersey].includes(option.clubId),
);

export const getShowAccidentMedicalPlan = createSelector(
  getClubId,
  (clubId) => clubId === ClubApp.MidStates || clubId === ClubApp.Northampton,
);
export const getShowGiftOption = createSelector(getClubId, (clubId) => [ClubApp.Hoosier, ClubApp.SouthJersey].includes(clubId));
export const getShowPassword = createSelector(getClubId, (clubId) =>
  [ClubApp.Hoosier, ClubApp.MidStates, ClubApp.SouthJersey, ClubApp.Northway].includes(clubId),
);
export const getIsRequirePhone = createSelector(getClubId, (clubId) =>
  [ClubApp.Northampton, ClubApp.SouthJersey].includes(clubId),
);
export const getShowCardFormat = createSelector(getClubId, (clubId) =>
  [ClubApp.Hoosier, ClubApp.SouthJersey].includes(clubId),
);

export const getCardTypes = createSelector(getClubId, (clubId) => {
  if (ClubApp.Hoosier === clubId) {
    return cardTypes;
  }

  if (ClubApp.SouthJersey === clubId) {
    return cardTypes.filter((c) => c.value !== CardFormat.BOTH);
  }

  return [];
});

export const getMedicalPlanText = createSelector(getClubOption, (option) => {
  if (option.clubId === ClubApp.Northampton) {
    return northamptonMedicalPlanText;
  }

  return '';
});

export const getMedicalPlanFullDescription = createSelector(getClubOption, (option) => {
  if (option.clubId === ClubApp.Northampton) {
    return northamptonMedicalPlanFullDescription;
  }

  return '';
});

export const getDefaultPaymentType = createSelector(getClubOption, (option) => {
  if (option.clubId === ClubApp.Northway) {
    return 'shift4' as PaymentType;
  }

  return 'cybersource';
});

export const getZipcodeFormDescription = createSelector(getClubOption, (option) =>
  getZipcodeDescription(option.clubId),
);

// Old query works with price preview
export const getQueryMembershipLevel = createSelector(
  getRouterQueryParams,
  getMembershipCodes,
  (queryParams, membershipCodes) =>
    membershipCodes.find(
      (membershipCode) => membershipCode.level === String(queryParams.membershiplevel).toUpperCase(),
    ),
);
