export function interfaceJoinRenewMembershipMembershipMzp(): string {
  return "interface-join-renew-membership-membership-mzp"
}

import {
  EventPayload,
  Operation,
  OperationEventResponse
} from "@aaa/interface-joinRenew-joinRenewLib"

/**
 * Membership Subsystem Events (MZP)
 */

export enum MembershipMzpMethod {
  IS_ALIVE = "IS_ALIVE",
  PRICE_PREVIEWS = "PRICE_PREVIEWS",

  LOOKUP_MEMBERSHIP = "LOOKUP_MEMBERSHIP",

  GET_MEMBERSHIP_COSTS_JOIN = "GET_MEMBERSHIP_COSTS_JOIN",
  GET_MEMBERSHIP_COSTS_RENEW = "GET_MEMBERSHIP_COSTS_RENEW",
  GET_MEMBERSHIP_COSTS_CHANGE = "GET_MEMBERSHIP_COSTS_CHANGE",

  OPERATION_EXECUTE = "OPERATION_EXECUTE",
}

export interface MembershipMzpEventPayload extends EventPayload {
  method: MembershipMzpMethod
  responseType?: unknown
}

export interface MembershipMzpIsAliveEventPayload extends MembershipMzpEventPayload {
  method: MembershipMzpMethod.IS_ALIVE

  responseType?: MembershipMzpIsAliveResponse
}

export interface MembershipMzpIsAliveResponse {
  isAlive: boolean
}

export interface MembershipMzpPricePreviewsEventPayload extends MembershipMzpEventPayload {
  method: MembershipMzpMethod.PRICE_PREVIEWS

  //required
  zipcode: string

  //options
  promoData: MzpPromoData

  responseType?: MembershipMzpPricePreviewsResponse
}

export interface MembershipMzpPricePreviewsResponse {
  prices: MzpPrices[]
  riders?: MzpRider[]
}

export interface MembershipMzpLookupMembershipEventPayload extends MembershipMzpEventPayload {
  method: MembershipMzpMethod.LOOKUP_MEMBERSHIP
  memberNumber: string // 7 digit

  threePointAuth?: MzpThreePointAuth // if omitted, will use event.afAuthIdToken

  responseType?: MembershipMzpLookupMembershipResponse
}

export interface MembershipMzpLookupMembershipResponse {
  memberInfo: unknown //TODO
}

export interface MembershipMzpGetMembershipCostsJoin extends MembershipMzpEventPayload {
  method: MembershipMzpMethod.GET_MEMBERSHIP_COSTS_JOIN

  zipcode: string
  membershipLevel: MzpMembershipLevel
  rv: boolean
  associateCount: number

  promoData?: MzpPromoData
  autoRenew?: boolean

  customers: MembershipMzpCustomer[]
  address?: MzpAddress

  responseType?: MembershipMzpGetMembershipCostsJoinResponse
}

export interface MembershipMzpGetMembershipCostsJoinResponse {
  validationData: any
  reservationKey?: string
  executionData: string
}

export interface MembershipMzpGetMembershipCostsRenew extends MembershipMzpEventPayload {
  method: MembershipMzpMethod.GET_MEMBERSHIP_COSTS_RENEW

  memberNumber: string

  threePointAuth?: MzpThreePointAuth // if omitted, will use event.afAuthIdToken

  promoData?: MzpPromoData
  autoRenew?: boolean

  responseType?: MembershipMzpGetMembershipCostsRenewResponse
}

export interface MembershipMzpGetMembershipCostsRenewResponse {
  validationData: any
  executionData: string
}

export interface MembershipMzpGetMembershipCostsChange extends MembershipMzpEventPayload {
  method: MembershipMzpMethod.GET_MEMBERSHIP_COSTS_CHANGE
  flow?: MembersphiMzpChangeFlow

  memberNumber: string

  promoData?: MzpPromoData

  //upgrade
  membershipLevel?: MzpMembershipLevel
  rv?: boolean

  //add associate or change customer
  // if (MembershipMzpCustomer.associateId === null) -> add associate
  // otherwise -> change customer data (primary and/or associates)
  customers?: MembershipMzpCustomer[]

  address?: MzpAddress

  //ar
  autoRenew?: boolean

  responseType?: MembershipMzpGetMembershipCostsChangeResponse
}

export interface MembershipMzpGetMembershipCostsChangeResponse<V = any> {
  validationData: V
  executionData: string
}

export interface MembershipMzpOperationExecuteEventPayload extends MembershipMzpEventPayload {
  method: MembershipMzpMethod.OPERATION_EXECUTE
  operation: Operation

  executionData: string
  loginCredentials?: DrupalNewLoginAccountCredentials // used for new joins, to create new login account

  payment?: any //NOT SUPPLIED BY UI, added by function during execution

  responseType?: OperationEventResponse<MzpOperationExecuteResponse>
}

export interface MzpOperationExecuteResponse {
  membershipId: string
  mzpResponse: any
  drupalResult: any
}

export interface DrupalNewLoginAccountCredentials {
  email?: string // can be extracted from primary member info
  encryptedPassword: string
  zip?: string // can be extracted from primary member info

  // membership number known after successful join
  iso?: string
  club?: string
  household?: string
  associate?: string
  check_digit?: string
}

export interface MzpThreePointAuth {
  memberNumber: string
  lastName: string
  zipcode: string
}

export type MzpPhoneType = "HOME" | "WORK" | "CELL" | "OTHER"

export type MembersphiMzpChangeFlow = "RENEW" | "AR" | "ASSOCIATE" | "LEVEL" | "CC"

/**
 * MZP Structs
 */
export interface MembershipMzpCustomer {
  associateId?: string // primary == '00'
  medical?: boolean
  firstName?: string
  lastName?: string
  suffix?: string
  birthDt?: string
  email?: string
  phones?: { type: MzpPhoneType; number: string }[]
}

export interface MzpAddress {
  addressLine1: string
  addressLine2?: string
  city: string
  state: string
  postalCode: string
}

export enum MzpMembershipLevel {
  BASIC = "BASIC",
  PLUS = "PLUS",
  PLUS_RV = "PLUS_RV",
  PREMIER = "PREMIER",
  PREMIER_RV = "PREMIER_RV",
}

export interface MzpPrices {
  level: MzpMembershipLevel
  rv: boolean
  code: string
  prices?: { members: number; price: number }[]
  enrollmentFee?: number
  primaryFee?: number
  associateFee?: number
}

export interface MzpRider {
  memberType: "primary" | "associate"
  code: string // ex: medical = 'MD'
  decription: string // ex: medical = 'Medical'
  price: number // ex: medical = 8.5
}

export interface MzpPromoData {
  promoCode?: "SHELL" | string
  couponCode?: string
  programCode?: string

  // once a couponCode is used for a recost, following recosts require reservationKey
  // (supplied in return paylod of first recost)
  reservationKey?: string
}
