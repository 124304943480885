import { Component, inject, Input } from '@angular/core';
import { ControlContainer, ControlValueAccessor, FormControl } from '@angular/forms';

@Component({ template: '' })
export abstract class FormBaseControlComponent implements ControlValueAccessor {
  container = inject(ControlContainer);
  @Input() controlId = `input${Math.ceil(Math.random() * 10000)}`;
  @Input() formControlName = '';
  @Input() placeholder = '';
  @Input() formLabel = '';
  @Input() errorMessages: { [key: string]: string } = {};
  showErrors = true;

  get formControl() {
    return this.container.control?.get(this.formControlName) as FormControl;
  }

  /**
   * The fix from this pending status
   * https://github.com/angular/angular/issues/41519
   */
  get formControlStatus() {
    if (this.formControl?.dirty) {
      switch (this.formControl.status) {
        case 'PENDING':
          return 'validating';
        case 'INVALID':
          return 'error';
        case 'VALID':
          return 'success';
        case 'DISABLED':
          return '';
      }
    }

    return '';
  }

  setDisabledState(isDisabled: boolean) {
    // Used directly control from parent
  }

  registerOnChange(fn: any) {
    // Used directly control from parent
  }

  registerOnTouched(fn: any) {
    // Used directly control from parent
  }

  writeValue(value: any): void {
    // Used directly control from parent
  }
}
