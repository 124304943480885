import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventName } from '@aaa/interface-joinRenew-joinRenewLib';
import { Store } from '@ngrx/store';
import { first, switchMap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { getHttpInterceptHeaders } from '../session/session.selectors';
import { AppStore } from '../types/store.model';

@Injectable({ providedIn: 'root' })
export class JoinRenewService {
  http = inject(HttpClient);
  store = inject(Store<AppStore>);

  sendToEventCoordinator<T>(eventName: EventName, payload: T) {
    return this.store.select(getHttpInterceptHeaders).pipe(
      first(),
      switchMap(({ sessionId, idTokenResult }) =>
        this.http.request('POST', environment.cloudFunctionsURL + '/joinRenew-eventCoordinatorReceiver', {
          headers: new HttpHeaders({}),
          body: {
            eventName,
            payload,
            sessionId,
            afAuthIdToken: idTokenResult?.token || '',
            created: null,
          },
        }),
      ),
    );
  }
}
