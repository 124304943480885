import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, map, of, switchMap, withLatestFrom } from 'rxjs';
import { Store } from '@ngrx/store';
import { MembershipActions } from '@aaa/emember/store-membership';
import { PriceOffersActions } from './price-offers.actions';
import { getPriceOfferQuery } from './price-offers.selectors';
import { RequestErrorType } from '../generic-errors';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';

@Injectable()
export class PriceOffersEffects {
  store = inject(Store);
  router = inject(Router);
  actions$ = inject(Actions);

  loadPriceOffers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MembershipActions.loadSucceeded),
      switchMap((action) => of(action).pipe(withLatestFrom(this.store.select(getPriceOfferQuery)))),
      map(([{ accountDetails }, pricePayload]) => {
        const query = { ...pricePayload, zipCode: accountDetails.address.zip };

        return PriceOffersActions.load({ query });
      }),
    ),
  );

  showContactWhenApisFails$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PriceOffersActions.loadFailed),
        filter(({ error }) => [RequestErrorType.PricePreviewError].includes(error.type)),
        tap(() => this.router.navigate(['contact'])),
      ),
    { dispatch: false },
  );
}
