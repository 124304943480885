import { RequestStatus } from '../../types/request-status';
import { RequestError, RequestErrorType } from '../store/generic-errors';
import { ClubOption } from '../../types/club-option';
import { TMobilePromoCodes, TMRShellPromoCodes } from './promocodes';

export const priceOfferCouponMessages = (
  requestStatus: RequestStatus,
  error: RequestError | null,
  option: ClubOption,
  couponCode?: string,
  programCode?: string,
) => {
  if (programCode && TMRShellPromoCodes.includes(programCode.toUpperCase())) {
    switch (requestStatus) {
      case RequestStatus.RUNNING:
        return `Retrieving Activation Code Savings for ${couponCode}`;
      case RequestStatus.SUCCESS:
        return 'Your AAA benefits are waiting! You have purchased a one-time credit for a new AAA Membership packed with a fullyear of exclusive member benefits! This single-use credit is valid for one year from today, but why wait? Complete your enrollment now and start enjoying your benefits today! Get Started!';
      case RequestStatus.FAILED: {
        switch (error?.type) {
          case RequestErrorType.PricePreviewRedeemedCouponCode:
            return `We're sorry. This coupon has already been redeemed. Please contact at <a href="tel:${option.phoneNumber}">${option.phoneNumberView}</a>
            for support.`;
          case RequestErrorType.PricePreviewReservedCouponCode:
            return "We're sorry. This coupon is temporarily unavailable, please try again later.";
          case RequestErrorType.PricePreviewInvalidCouponCode:
            return `We're sorry. This coupon is invalid or expired. Please contact <a href="tel:${option.phoneNumber}">${option.phoneNumberView}</a> for support.`;
          default:
            return `We faced a problem while trying to redeem your offer. Please contact <a href="tel:${option.phoneNumber}">${option.phoneNumberView}</a> for support.`;
        }
      }
    }
  }

  if (programCode && TMobilePromoCodes.includes(programCode.toUpperCase())) {
    switch (requestStatus) {
      case RequestStatus.RUNNING:
        return `Retrieving Activation Code Savings for ${couponCode}`;
      case RequestStatus.SUCCESS:
        return 'Success! You are eligible for a one-time credit towards a new AAA Membership, courtesy of T-Mobile (terms and conditions apply). Each coupon code is unique, valid for 1 year from the issue date and can only be redeemed once.';
      case RequestStatus.FAILED: {
        switch (error?.type) {
          case RequestErrorType.PricePreviewRedeemedCouponCode:
            return `We're sorry. This coupon has already been redeemed. Please contact T-Mobile  <a href="tel:${option.phoneNumber}">${option.phoneNumberView}</a>
            for support.`;
          case RequestErrorType.PricePreviewReservedCouponCode:
            return "We're sorry. This coupon is temporarily unavailable, please try again later.";
          case RequestErrorType.PricePreviewInvalidCouponCode:
            return `We're sorry. This coupon is invalid or expired. Please contact T-Mobile <a href="tel:${option.phoneNumber}">${option.phoneNumberView}</a> for support.`;
          default:
            return `We faced a problem while trying to redeem your offer. Please contact <a href="tel:${option.phoneNumber}">${option.phoneNumberView}</a> for support.`;
        }
      }
    }
  }

  return '';
};
