import { MembershipLevelOffer, MembershipsLevel } from '@aaa/emember/store-price-offers';
import { MembershipOffering, MembershipOfferItem } from '../store/price-offers/helpers/types';

export function findLevelOfferAmount(
  level: MembershipsLevel | undefined,
  levelOffers: MembershipLevelOffer | null,
  offering: MembershipOffering,
) {
  const offer =
    levelOffers &&
    level &&
    (levelOffers[level.membershipType]?.find((o) => o.offering === offering) as MembershipOfferItem);

  return offer ? '$' + offer.amount.toFixed(2) : '';
}
