import { createReducer, on } from '@ngrx/store';
import { RequestStatus } from '../../../types/request-status';
import { ValidatorsActions } from './validators.actions';
import { ValidatorsState } from './validators.model';

export const initialStateValidators: ValidatorsState = {
  zipCodeInClubRequestStatus: RequestStatus.UNSENT,
  zipCodeInClubResponse: null,
  emailExistInClubRequestStatus: RequestStatus.UNSENT,
  emailExistInClubResponse: null,
};

export const validatorsReducer = createReducer(
  initialStateValidators,
  on(ValidatorsActions.zipCodeInClub, (state) => ({
    ...state,
    zipCodeInClubResponse: null,
    zipCodeInClubRequestStatus: RequestStatus.RUNNING,
  })),
  on(ValidatorsActions.zipCodeInClubFailed, (state, { zipcodeIsNotInsideClub }) => ({
    ...state,
    zipCodeInClubResponse: { zipcodeIsNotInsideClub },
    zipCodeInClubRequestStatus: RequestStatus.FAILED,
  })),
  on(ValidatorsActions.zipCodeInClubSucceeded, (state) => ({
    ...state,
    zipCodeInClubResponse: null,
    zipCodeInClubRequestStatus: RequestStatus.SUCCESS,
  })),
  on(ValidatorsActions.emailExistInClub, (state) => ({
    ...state,
    emailExistInClubResponse: null,
    emailExistInClubRequestStatus: RequestStatus.RUNNING,
  })),
  on(ValidatorsActions.emailExistInClubFailed, (state, { emailExists, lookupFailed, unableToVerify }) => {
    return {
      ...state,
      emailExistInClubResponse: {
        ...(emailExists ? { emailExists } : null),
        ...(lookupFailed ? { lookupFailed } : null),
        ...(unableToVerify ? { unableToVerify } : null),
      },
      emailExistInClubRequestStatus: RequestStatus.FAILED,
    };
  }),
  on(ValidatorsActions.emailExistInClubSucceeded, (state) => ({
    ...state,
    emailExistInClubResponse: null,
    emailExistInClubRequestStatus: RequestStatus.SUCCESS,
  })),
);
