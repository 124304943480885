import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const ValidatorsActions = createActionGroup({
  source: 'Validators/API',
  events: {
    'Zip Code In Club': props<{ zipcode: string }>(),
    'Zip Code In Club Succeeded': emptyProps(),
    'Zip Code In Club Failed': props<{ zipcodeIsNotInsideClub: boolean }>(),
    'Email Exist In Club': props<{ email: string }>(),
    'Email Exist In Club Succeeded': emptyProps(),
    'Email Exist In Club Failed': props<{ emailExists?: boolean; unableToVerify?: boolean; lookupFailed?: boolean }>(),
  },
});
