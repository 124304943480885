export enum FieldComponentType {
  ICON = 'icon',
  PARAGRAPH = 'paragraph',
  SPAN = 'SPAN',
  SECTION = 'section',
  HEADER2 = 'header2',
  HEADER3 = 'header3',
  BR = 'br',
}

export type FieldIconProps = { icon: string; cls?: string };
export type FieldParagraphProps = { text: string; cls?: string };
export type FieldSpanProps = { text: string; cls?: string };
export type FieldH2Props = { text: string; cls?: string; textBold?: boolean };
export type FieldH3Props = { text: string; cls?: string; textBold?: boolean };
export type FieldSectionProps = { cls?: string };

export type FieldComponent = {
  type: FieldComponentType;
  props?: FieldIconProps | FieldParagraphProps | FieldSectionProps | FieldH2Props | FieldSpanProps | FieldH3Props;
  fieldGroup?: FieldComponent[];
};
