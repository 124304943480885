import { JoinPage } from '@aaa/emember/store-join';

const giftMessage = `Gift Membership is automatically renewed each year to the payment card you provided. You will receive an annual notice prior to your renewal date, which notice will state the term of your next membership period and the total dues to be charged to your card. You can cancel automatic renewal anytime by calling Member Services. `;

export const hoosierTermAndCondition = (page: JoinPage) => {
  return [
    'As a AAA member, you receive a membership card valid for one full year. It entitles you to benefits as a passenger or as a driver, so carry it with you at all times!',
    'AAA Plus and AAA Premier membership extended roadside benefits are available 72 hours after payment is received. AAA Classic road service benefits are available immediately to ALL members.',
    'Dues for primary membership include $1 for a non-discountable annual subscription to Crossroads magazine.',
    'NOTE: All benefits are subject to AAA terms and conditions. Have questions or concerns? Contact us 1-844-GO-AAA-GO',
    page === 'gift' ? giftMessage : '',
  ];
};

export const midstatesTermAndCondition = (page: JoinPage) => {
  return [
    'By checking this box, I declare that I am an authorized signer on the credit/debit account I entered. I am hereby authorizing you to charge or debit the account, as applicable, indicated for all amounts relating to my membership that become due by me to you.',
    'These amounts include, without limitation, all membership dues, and all related finance charges, late payment, and other fees and to charge or debit the same to such account, as applicable, for the membership specified above.',
    page === 'gift' ? giftMessage : '',
  ];
};

export const northamptonTermAndCondition = (page: JoinPage) => {
  return [
    'By checking this box, I declare that I am an authorized signer on the credit/debit account I entered. I am hereby authorizing you to charge or debit the account, as applicable, indicated for all amounts relating to my membership that become due by me to you.',
    'These amounts include, without limitation, all membership dues, and all related finance charges, late payment, and other fees and to charge or debit the same to such account, as applicable, for the membership specified above.',
    page === 'join'
      ? 'New members requesting service on day joined are subject to a $50.00 fee. AAA Northampton County has a 10 day waiting period for Plus and Premier services.'
      : '',
    page === 'gift' ? giftMessage : '',
  ];
};

export const shelbyTermAndCondition = (page: JoinPage) => {
  return [
    'By checking this box, I declare that I am an authorized signer on the credit/debit account I entered. I am hereby authorizing you to charge or debit the account, as applicable, indicated for all amounts relating to my membership that become due by me to you.',
    'These amounts include, without limitation, all membership dues, and all related finance charges, late payment, and other fees and to charge or debit the same to such account, as applicable, for the membership specified above.',
    page === 'gift' ? giftMessage : '',
  ];
};

export const southJerseyTermAndCondition = (page: JoinPage) => {
  return [
    'As a AAA member, you receive a membership card valid for one full year. It entitles you to benefits as a passenger or as a driver, so carry it with you at all times! Road service benefits are available 24 hours after membership purchase. There is a seven-day waiting period for Plus and Premier services.',
    'NOTE: All benefits are subject to AAA terms and conditions. Have questions or concerns? Contact Member Services at 1-855-772-5551.',
    page === 'gift' ? giftMessage : '',
  ];
};
