import { ConnectSuite } from './connect-suite.type';
import MemberInfo = ConnectSuite.MemberInfo;
import { LastNameSuffix } from '@aaa/interface-joinRenew-membership-membershipConnectSuite';
import { MembershipAssociateDetails } from '@aaa/emember/store-types';

export type PhoneType = 'cell' | 'home' | 'business';

export type AccountPhone = { [key in PhoneType]: string };

export type AccountAddress = {
  street1: string;
  street2: string;
  city: string;
  state: string;
  zip: string;
};

export const getConnectsuiteAccountAddress = (memberInfo?: MemberInfo): AccountAddress => {
  return {
    street1: memberInfo?.membership.address.attributes.address1 || '',
    street2: memberInfo?.membership.address.attributes.address2 || '',
    city: memberInfo?.membership.address.attributes.cityName || '',
    state: memberInfo?.membership.address.attributes.StateProv || '',
    zip: memberInfo?.membership.address.attributes.postalCode || '',
  };
};

export const getConnectsuiteAccountPhone = (memberInfo?: MemberInfo): AccountPhone => {
  return {
    cell: memberInfo?.membership.primaryMember.attributes.cellPhone || '',
    home: memberInfo?.membership.primaryMember.attributes.homePhone || '',
    business: memberInfo?.membership.primaryMember.attributes.businessPhone || '',
  };
};

export const getConnectsuiteMembershipAssociates = (memberInfo?: MemberInfo): MembershipAssociateDetails[] => {
  return (memberInfo?.membership.associateMember || []).map((associate) => {
    return {
      dob: associate.attributes.dob,
      email: associate.attributes.email || '',
      firstName: associate.attributes.firstName,
      lastName: associate.attributes.lastName,
      membershipNumber: associate.attributes.membershipNumber,
      middleIntial: associate.attributes.middleIntial,
      nameSuffix: associate.attributes.nameSuffix as LastNameSuffix,
      removeAssociate: false,
      status: 'active',
      // Todo
      offerItems: [],
    };
  });
};
