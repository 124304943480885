import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RequestStatus } from '../../../types/request-status';
import { MembershipRenewState } from './membership-renew.models';
import { getClubOption } from '@aaa/emember/store-membership';
import { getErrorMessage } from '../generic-errors';
import { AccountDetailsSelectors } from '../../modules/share/ngrx-helpers/account-details.selectors';

export const MEMBERSHIP_RENEW_FEATURE_KEY = 'membership-renew';

export const getMembershipRenewFormState = createFeatureSelector<MembershipRenewState>(MEMBERSHIP_RENEW_FEATURE_KEY);
export const getMembershipRenewExecutionData = createSelector(
  getMembershipRenewFormState,
  ({ executionData }) => executionData,
);
export const getMembershipRenewExecutionError = createSelector(
  getMembershipRenewFormState,
  ({ executionError }) => executionError,
);
export const getMembershipRenewAccountDetails = createSelector(
  getMembershipRenewFormState,
  ({ accountDetails }) => accountDetails,
);
export const MembershipRenewAccountSelector = AccountDetailsSelectors(getMembershipRenewAccountDetails, getClubOption);
export const getMembershipRenewExecutionRequestStatus = createSelector(
  getMembershipRenewFormState,
  ({ executionRequestStatus }) => executionRequestStatus,
);
export const getMembershipRenewFormActiveStep = createSelector(
  getMembershipRenewFormState,
  ({ formActiveStep }) => formActiveStep,
);
export const getMembershipRenewFormValues = createSelector(getMembershipRenewFormState, ({ formValues }) => formValues);
export const getMembershipRenewValidationRequestStatus = createSelector(
  getMembershipRenewFormState,
  ({ validationRequestStatus }) => validationRequestStatus,
);
export const getMembershipRenewErrors = createSelector(
  getMembershipRenewFormState,
  ({ validationError, executionError }) => validationError || executionError,
);
export const getMembershipRenewErrorsMessage = createSelector(
  getMembershipRenewErrors,
  getClubOption,
  (error, options) => getErrorMessage(error, options),
);

export const getMembershipRenewIsLoadingValidation = createSelector(
  getMembershipRenewValidationRequestStatus,
  (status) => status === RequestStatus.RUNNING,
);
export const getMembershipRenewIsLoadingExecution = createSelector(
  getMembershipRenewExecutionRequestStatus,
  (status) => status === RequestStatus.RUNNING,
);
