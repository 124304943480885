import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { PaymentStatus, PaymentType } from './payment.model';
import { FormGroupValue } from '../../modules/share/form.utils';
import { PaymentForm } from '@aaa/emember/share/payment-form';
import { RequestError } from '../generic-errors';

export const PaymentActions = createActionGroup({
  source: 'Payment',
  events: {
    'Set Auto Renew': props<{ value: boolean }>(),
    'Changed Auto Renew': props<{ value: boolean }>(),
    'Changed Form': props<{ values: FormGroupValue<PaymentForm> }>(),
    'Changed Status': props<{ paymentStatus: PaymentStatus }>(),
    'Init Card Form': props<{ paymentType: PaymentType }>(),
    'Init Card Form Succeeded': props<{ sessionToken: any }>(),
    'Init Card Form Failed': props<{ error: RequestError }>(),
    'Generate Token': emptyProps(),
    'Generate Token Succeeded': props<{ paymentToken: string }>(),
    'Generate Token Failed': props<{ error: RequestError }>(),
    Validate: emptyProps(),
    'Validate Succeeded': props<{ token?: string }>(),
    'Validate Failed': emptyProps(),
    Reset: emptyProps(),
  },
});
