import { MembershipOfferItem, MembershipOfferCondition, OfferOperator } from './types';
import { ConnectSuite } from '../../connect-suite.type';

const convertRule = (rules: ConnectSuite.MembershipOfferingDuesComponent['rule']): MembershipOfferCondition[] => {
  const conditions: MembershipOfferCondition[] = [];
  const convertOperand = (operator: string): OfferOperator => {
    switch (operator) {
      case 'ge':
        return 'moreThanOrEqual';
      case 'gt':
        return 'moreThan';
      case 'le':
        return 'lessThanOrEqual';
      case 'lt':
        return 'lessThan';
      default:
        return 'equal';
    }
  };

  if (rules.associateCount) {
    conditions.push({
      apply: 'associateCount',
      value: Number(rules.associateCount.attributes.value),
      operator: convertOperand(rules.associateCount.attributes.operand),
    });
  }

  return conditions;
};

export function convertMembershipOfferComponents(
  offering?: ConnectSuite.MembershipOffering | null,
): MembershipOfferItem[] {
  const components: MembershipOfferItem[] = [];

  if (offering) {
    offering.associateOfferings?.forEach((offering) => {
      offering.duesComponent.forEach((component, index) => {
        components.push({
          code: component.attributes.componentCode,
          description: component.attributes.componentDescription,
          amount: Number(component.attributes.componentAmount),
          offering: index === 0 ? 'associate' : 'associateOptional',
          selectedByDefault: offering?.attributes.selectOptionBydefault == 'Y',
          conditions: [...convertRule(component.rule)],
        });
      });
    });

    components.push({
      offering: 'primary',
      code: offering.duesComponent.attributes.componentCode,
      description: offering.duesComponent.attributes.componentDescription,
      amount: Number(offering.duesComponent.attributes.componentAmount),
      selectedByDefault: true,
      conditions: [],
    });
  }

  return components;
}
