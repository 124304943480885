import { MembershipOfferPage } from '@aaa/emember/store-membership-offers';
import { FieldComponent, FieldComponentType } from '../../modules/render';

export const membershipOffers = (page: MembershipOfferPage): FieldComponent[] => {
  const fields: FieldComponent[] = [];

  switch (page) {
    case 'offers':
      {
        fields.push({
          type: FieldComponentType.HEADER2,
          props: { text: 'AAA Membership', cls: 'ava-text-xxl ava-text-bolder ava-text-white ava-pb-2 ava-mb-2' },
        });

        const texts = [
          "Roadside Assistance in any car — whether you're the driver or a passenger",
          'Each member receives up to four service calls per membership year',
          'Discounts at over 100,000 locations including hotels, restaurants, and car repair shops',
          'Full-service vacation planning with exclusive discounts and extras for members',
        ];
        for (const text of texts) {
          const section: FieldComponent = {
            type: FieldComponentType.SECTION,
            props: { cls: 'ava-row ava-gap-1' },
            fieldGroup: [
              { type: FieldComponentType.ICON, props: { icon: 'icons:checkmark', cls: 'ava-icon-red' } },
              {
                type: FieldComponentType.PARAGRAPH,
                props: { cls: 'ava-text-white', text: text },
              },
            ],
          };

          fields.push(section);
        }
      }
      break;
    case 'gift-offers':
      {
        fields.push(
          {
            type: FieldComponentType.PARAGRAPH,
            props: { text: 'Give the Gift of', cls: 'ava-text-xxl ava-bold-400 ava-pb-0 ava-mb-0' },
          },
          {
            type: FieldComponentType.PARAGRAPH,
            props: { text: 'Peace of Mind', cls: 'ava-text-xxl ava-bold-400 ava-pb-2 ava-mb-2' },
          },
        );

        const texts = [
          'Give the Gift of Peace of Mind with 24/7 Emergency Roadside Assistance Coverage, ',
          'Provided 365 Days a Year.',
        ];
        for (const text of texts) {
          fields.push({
            type: FieldComponentType.PARAGRAPH,
            props: { text },
          });
        }
      }
      break;
  }

  return fields;
};
