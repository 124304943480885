import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { FieldComponent, FieldComponentType, FieldSectionProps } from './types';
import { JsonPipe, NgForOf, NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import { RenderIconComponent } from './components/render-icon/render-icon.component';
import { RenderParagraphComponent } from './components/render-paragraph/render-paragraph.component';
import { RenderSpanComponent } from './components/render-span.component';
import { RenderH2Component } from './components/render-h2.component';
import { RenderH3Component } from './components/render-h3.component';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[ava-render]',
  template: `
    <ng-container *ngFor="let field of fields">
      <ng-container [ngSwitch]="field.type">
        <ng-container *ngSwitchCase="FieldComponentType.ICON">
          <div ava-render-icon [props]="field.props"></div>
        </ng-container>
        <ng-container *ngSwitchCase="FieldComponentType.PARAGRAPH">
          <p ava-render-paragraph [props]="field.props"></p>
        </ng-container>
        <ng-container *ngSwitchCase="FieldComponentType.SPAN">
          <span ava-render-span [props]="field.props"></span>
        </ng-container>
        <ng-container *ngSwitchCase="FieldComponentType.HEADER2">
          <h2 ava-render-h2 [props]="field.props"></h2>
        </ng-container>
        <ng-container *ngSwitchCase="FieldComponentType.HEADER3">
          <h2 ava-render-h3 [props]="field.props"></h2>
        </ng-container>
        <ng-container *ngSwitchCase="FieldComponentType.BR"><br /></ng-container>
        <ng-container *ngSwitchCase="FieldComponentType.SECTION">
          <section ava-render [props]="field.props" [fields]="field.fieldGroup"></section>
        </ng-container>
      </ng-container>
    </ng-container>
  `,
  standalone: true,
  imports: [
    NgForOf,
    NgSwitchCase,
    NgSwitch,
    RenderIconComponent,
    RenderParagraphComponent,
    JsonPipe,
    NgIf,
    RenderSpanComponent,
    RenderH2Component,
    RenderH3Component,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RenderComponent {
  readonly FieldComponentType = FieldComponentType;
  @Input() fields: FieldComponent[] = [];
  @Input() props?: FieldSectionProps;

  @HostBinding('class') get getClass() {
    return this.props?.cls || '';
  }
}
