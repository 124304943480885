import { JoinPage } from '@aaa/emember/store-join';
import { TMRShellPromoCodes } from '../../../constants/promocodes';
import { ClubApp } from '@aaa/emember/types';

export function paymentAutoRenewInfo(page: JoinPage, clubId: ClubApp, programCode?: string) {
  if (programCode && TMRShellPromoCodes.includes(programCode)) {
    return 'Auto Renewal is required for enrollment. You may cancel Auto Renew up to approximately 30 days prior to membership expiration date.';
  }

  if (page === 'gift') {
    return "All Gift Memberships require auto renew.  When it's time to renew your gift membership, your annual dues will automatically be charged to the credit card approximately 20 days prior to your membership expiration date.";
  }

  return `When it's time to renew your membership, your annual dues will automatically be charged to the credit card approximately ${(() => {
    switch (clubId) {
      case ClubApp.SouthJersey:
        return '15';
      case ClubApp.MidStates:
        return '30';
      default:
        return '20';
    }
  })()} days prior to your membership expiration date.`;
}
