import { Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';
import { NgIf } from '@angular/common';
import { FieldH2Props } from '../types';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[ava-render-h2]',
  template: `
    <ng-container *ngIf="props">
      <ng-container *ngIf="props.textBold; else default">
        <strong>{{ props?.text }}</strong>
      </ng-container>
      <ng-template #default>
        {{ props?.text }}
      </ng-template>
    </ng-container>
  `,
  standalone: true,
  imports: [NgIf],
  encapsulation: ViewEncapsulation.None,
})
export class RenderH2Component {
  @Input() props?: FieldH2Props;
  @HostBinding('class') get getClass() {
    return this.props?.cls || '';
  }
}
