import { ClubApp } from '@aaa/emember/types';

const config: { [key: string]: string } = {
  [ClubApp.Northampton]:
    'AAA Northampton County serves AAA members in the following areas: In Northampton County: the greater Easton area, Bangor, Bethlehem Twp, Bushkill Twp, Mt. Bethel, the greater Nazareth area, Pen Argyl, Plainfield Twp, Portland, Roseta, Stockertown, Tatamy, Washington Twp, Wind Gap and North-Eastern Bucks County.',
  [ClubApp.Shelby]: 'AAA Shelby County serves AAA members in the following area(s): Shelby County, Ohio.',
  [ClubApp.Northway]:
    'AAA Northway proudly serves AAA members in Broome, Chemung, Chenango (Town of Greene), Clinton, Delaware (Town of Deposit), Essex, Franklin, Fulton, Hamilton, Montgomery, Saratoga [except Stillwater, Waterford and Mechanicville], Schenectady, Tioga, Warren and Washington counties.',
};

export function getZipcodeDescription(clubId: ClubApp) {
  return config[clubId] || '';
}
