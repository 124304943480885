import { isArray } from 'lodash-es';
import { isEqual } from 'lodash-es';
import { isObject } from 'lodash-es';
import { transform } from 'lodash-es';

export function differenceBetween(origObj: any, newObj: any) {
  function changes(newObj: any, origObj: any) {
    let arrayIndexCounter = 0;

    return transform(newObj, (result: any, value, key) => {
      if (!isEqual(value, origObj[key])) {
        const resultKey = isArray(origObj) ? arrayIndexCounter++ : key;
        result[resultKey] = isObject(value) && isObject(origObj[key]) ? changes(value, origObj[key]) : value;
      }
    });
  }

  return changes(newObj, origObj);
}
