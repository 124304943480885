import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzNoAnimationModule } from 'ng-zorro-antd/core/no-animation';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';

@Component({
  standalone: true,
  selector: 'ava-icon',
  template: `
    <i
      [style.font-size]="iconSize + 'px'"
      nz-icon
      [nzTheme]="iconTheme"
      [nzType]="iconType"
      [hidden]="false"
      nz-tooltip
      [nzTooltipTitle]="tooltipTitle"
      [nzTooltipPlacement]="tooltipPlacement"
      (click)="tooltipClicked.emit()"
    ></i>
  `,
  styles: [
    `
      :host {
        cursor: pointer;
        color: var(--ava-icon-color);

        &:hover {
          color: var(--ava-icon-hover-color);
        }
      }
    `,
  ],
  imports: [NzNoAnimationModule, NzIconModule, CommonModule, NzToolTipModule],
})
export class AvaIconComponent {
  @Input() iconType = '';
  @Input() iconSize = 14;
  @Input() iconTheme: 'fill' | 'outline' | 'twotone' = 'outline';
  @Input() tooltipTitle: string | TemplateRef<void> = '';
  @Input() tooltipPlacement: string | string[] = 'bottom';
  @Output() tooltipClicked = new EventEmitter();
}
