import { createActionGroup, emptyProps, props } from '@ngrx/store';
import firebase from 'firebase/compat/app';
import IdTokenResult = firebase.auth.IdTokenResult;
import { SessionDoc } from './session.models';

export const SessionActions = createActionGroup({
  source: 'Session',
  events: {
    Initialize: emptyProps(),
    'Initialize Succeeded': props<{ sessionId: string; idTokenResult: IdTokenResult | null }>(),
    'Set Id': props<{ sessionId: string }>(),
    'Set Id Token': props<{ idTokenResult: IdTokenResult | null }>(),
    'Set Docs': props<{ sessionDocs?: SessionDoc }>(),
  },
});
