import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FeatureFlagsState } from './types';

export const FEATURE_FLAGS_KEY = 'feature-flags';

const featureFlagsState = createFeatureSelector<FeatureFlagsState>(FEATURE_FLAGS_KEY);

export const getFeatureFlags = createSelector(featureFlagsState, (state) => state.flags);

export const isFeatureFlagEnabled = (flagName: string) =>
  createSelector(getFeatureFlags, (state) => !!state?.[flagName]);
