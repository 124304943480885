import { Store } from '@ngrx/store';
import { filter, Observable, withLatestFrom } from 'rxjs';
import { getClubId } from '@aaa/emember/store-membership';
import { map } from 'rxjs/operators';
import { AppStore } from '../types/store.model';
import { ClubApp } from '@aaa/emember/types';

export function filterByClubIds(store: Store<AppStore>, clubIds: ClubApp[]) {
  return function <T>(source: Observable<T>) {
    return source.pipe(
      withLatestFrom(store.select(getClubId)),
      filter(([, activeClubId]) => clubIds.includes(activeClubId)),
      map(([action]) => action),
    );
  };
}
