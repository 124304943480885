import { ClearCacheSettings, SaveCacheSettings } from '@aaa/interface-joinRenew-joinRenewLib';
import { getCurrentTimeStamp } from './get-current-timestamp';

export const getClearCacheSettings = (clearContext?: string | null): ClearCacheSettings => {
  return { cacheType: 'clear', clearContext: clearContext || '' };
};

export const getSaveCacheSettings = (context = 'globalContext'): SaveCacheSettings => {
  return {
    cacheType: 'save',
    context: context,
    maxAge: getCurrentTimeStamp(-24 * 3600 * 1000),
  };
};
