import { createMembershipOfferSummary } from './create-membership-offer-summary';
import { MembershipLevelOffer } from '../price-offers.model';
import { MembershipOfferSummary, OfferFilter } from './types';

export function currentSummaryOffer(
  membershipOffers: MembershipLevelOffer | null,
  membershipType: string,
  offerFilter: OfferFilter,
): MembershipOfferSummary {
  if (membershipOffers && membershipType) {
    const currentMembershipOffers = membershipOffers[membershipType];

    return createMembershipOfferSummary(currentMembershipOffers, offerFilter);
  }

  return {
    primary: undefined,
    optionalPrimary: [],
    associates: [],
    optional: [],
  };
}
