import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ValidatorsState } from './validators.model';
import { RequestStatus } from '../../../types/request-status';

export const VALIDATORS_FEATURE_KEY = 'validators';

export const getValidatorsState = createFeatureSelector<ValidatorsState>(VALIDATORS_FEATURE_KEY);
export const getZipCodeInClubRequestStatus = createSelector(
  getValidatorsState,
  ({ zipCodeInClubRequestStatus }) => zipCodeInClubRequestStatus,
);
export const getZipCodeInClubResponse = createSelector(
  getValidatorsState,
  ({ zipCodeInClubResponse }) => zipCodeInClubResponse,
);

export const getZipCodeInClubResponseDone = createSelector(
  getZipCodeInClubRequestStatus,
  getZipCodeInClubResponse,
  (status, response) => ({ status: status !== RequestStatus.RUNNING, response }),
);

export const getZipCodeInClubIsLoading = createSelector(
  getZipCodeInClubRequestStatus,
  getZipCodeInClubResponse,
  (status) => status === RequestStatus.RUNNING,
);

export const getZipCodeInClubFailed = createSelector(
  getZipCodeInClubRequestStatus,
  getZipCodeInClubResponse,
  (status) => status === RequestStatus.FAILED,
);

export const getZipCodeInClubSuccess = createSelector(
  getZipCodeInClubRequestStatus,
  getZipCodeInClubResponse,
  (status) => status === RequestStatus.SUCCESS,
);

export const getEmailExistInClubRequestStatus = createSelector(
  getValidatorsState,
  ({ emailExistInClubRequestStatus }) => emailExistInClubRequestStatus,
);
export const getEmailExistInClubResponse = createSelector(
  getValidatorsState,
  ({ emailExistInClubResponse }) => emailExistInClubResponse,
);

export const getEmailExistInClubResponseDone = createSelector(
  getEmailExistInClubRequestStatus,
  getEmailExistInClubResponse,
  (status, response) => ({ status: status !== RequestStatus.RUNNING, response }),
);
