import { ComparePlanItem } from '../../../types/compare-plan';
import { MembershipLevelOffer, MembershipsLevel } from '@aaa/emember/store-price-offers';
import { ConnectSuiteMembershipLevel } from '@aaa/interface-joinRenew-membership-membershipConnectSuite';
import { findLevelOfferAmount } from '../utils';

export const compareCharts = (
  levels: MembershipsLevel[],
  levelOffers: MembershipLevelOffer | null,
): ComparePlanItem[][] => {
  const basicLevel = levels.find((l) => !l.rv && l.level === ConnectSuiteMembershipLevel.CLASSIC);
  const plusLevel = levels.find((l) => !l.rv && l.level === ConnectSuiteMembershipLevel.PLUS);
  // const plusRVLevel = levels.find((l) => l.rv && l.level === ConnectSuiteMembershipLevel.PLUS)
  const premierLevel = levels.find((l) => !l.rv && l.level === ConnectSuiteMembershipLevel.PREMIER);

  return [
    [
      {
        title: 'Get RV and Motorcycle coverage',
        description:
          'Add coverage for Motorcycles, Motor homes, Pickup with camper, Travel trailers, and Camping trailers.',
      },
      {
        description: 'Coverage unavailable for Basic membership levels',
      },
      {
        button: true,
        buttonLabel: 'PLUS RV',
        buttonId: 'plus_rv',
        buttonParam: plusLevel?.level ? { membershiplevel: plusLevel.level, rv: true } : {},
      },
      {
        description: 'Coverage unavailable for Premier membership level',
      },
    ],
    [
      {
        title: 'Add a Family Member',
        description:
          "Membership may be extended to anyone living in the primary member's household or to students away at college. Each associate membership provides the same privileges as the primary member and will carry their own card.",
      },
      { description: `Reg ${findLevelOfferAmount(basicLevel, levelOffers, 'associate')} each` },
      { description: `Reg ${findLevelOfferAmount(plusLevel, levelOffers, 'associate')} each` },
      { description: `Reg ${findLevelOfferAmount(premierLevel, levelOffers, 'associate')} each` },
    ],
    [
      {
        title: 'Towing Coverage',
        description:
          'When you need us most - get reliable 24/7 roadside assistance, available throughout the USA and Canada.',
      },
      {
        description: basicLevel?.level ? `Up to ${basicLevel.towMiles} miles` : '',
      },
      {
        description: plusLevel?.level ? `Up to ${plusLevel.towMiles} miles` : '',
      },
      {
        description: 'One tow per household up to 200 miles, remaining tows up to 100 miles',
      },
    ],
    [
      {
        title: 'Number of Service Calls',
        description:
          'Need a tow or other service call? The primary member and each associate on the account can receive up to the alloted number of service calls per membership year.',
      },
      {
        description: '4 calls',
      },
      {
        description: `4 calls`,
      },
      {
        description: `4 calls`,
      },
    ],
    [
      {
        title: 'Vehicle Lockout / Locksmith Service',
        description:
          'If key is locked inside vehicle, lost or broken; locksmith service is covered up to the limit of benefit.',
      },
      {
        description: 'Up to $50',
      },
      {
        description: 'Up to $100',
      },
      {
        description: 'Up to $150',
      },
    ],
    [
      {
        title: 'Emergency Fuel Delivery',
        description:
          'When a vehicle runs out of fuel, a service technician will bring enough fuel to reach the nearest service station, or the vehicle will be towed to a facility where fuel is available. AAA cannot guarantee specific brands, octane ratings, or the availability of diesel fuel. If the vehicle will not start after fueling, towing may be provided.',
      },
      {
        description: 'Pay pump price, free delivery',
      },
      {
        description: 'Free fuel, free delivery',
      },
      {
        description: 'Free fuel, free delivery',
      },
    ],
    [
      {
        title: 'Extrication',
        description:
          'When a vehicle becomes stuck and can be safely reached from a normally traveled roadway, service will be provided using standard automobile servicing equipment. If the vehicle cannot be safely driven after winching, it may be towed. Plus RV coverage may be required, see handbook for restrictions.',
      },
      {
        description: 'One driver/tow truck',
      },
      {
        description: 'Two drivers/two trucks, if needed',
      },
      {
        description: 'Two drivers/two trucks, if needed.',
      },
    ],
    [
      {
        title: 'Motorcycle Roadside Assistance Coverage',
        description:
          'An extension of AAA Plus. Coverage may be added to two or three wheel motor driven vehicles legally licensed for normal roadway use. Restrictions may apply*.',
      },
      {},
      {
        description: 'Choose AAA Plus RV',
      },
      {},
    ],
    [
      {
        title: 'RV Roadside Assistance Coverage',
        description:
          'An extension of AAA Plus. RV is considered to be a motor home, truck with camper, large dual wheel pickup, travel trailer or camping trailer. Restrictions may apply*.',
      },
      {},
      {
        description: 'Choose AAA Plus RV',
      },
      {},
    ],
    [
      {
        title: 'Auto Theft Reward',
        description:
          "As a deterrent to theft and vandalism, a member's car is protected by a warning stating that AAA offers a $$$ reward for information leading directly to the arrest and conviction of anyone stealing a member's car or instance involving a hit and run. Restrictions apply.",
      },
      {
        description: '$500 reward',
      },
      {
        description: '$1,000 reward',
      },
      {
        description: '$1,500 reward',
      },
    ],
    [
      {
        title: 'Travel Accident Insurance',
        description:
          'When you purchase scheduled airline, train, bus or cruise tickets at your local AAA Travel Agency, you are automatically covered with up to $100,000/$400,000 in travel accident insurance at no cost.',
      },
      {
        description: 'Up to $100,000',
      },
      {
        description: 'Up to $400,000',
      },
      {
        description: 'Up to $400,000',
      },
    ],
    [
      {
        title: 'Auto Trip Interruption Coverage',
        description:
          'As a AAA member, if you are involved in a collision while driving or riding in an eligible vehicle, which disables your vehicle when you are 50 miles or more away from home, AAA will reimburse for meals, accommodations and suitable transportation. Restrictions apply.',
      },
      {
        description: 'Up to $600',
      },
      {
        description: 'Up to $1,000',
      },
      {
        description: 'Up to $1,500',
      },
    ],
    [
      {
        title: 'Travel Store Discount',
        description: 'Find great deals on name-brand luggage, travel essentials, and more!',
      },
      {
        description: 'Member pricing',
      },
      {
        description: 'Member pricing and additional <br /> 10% off',
      },
      {
        description: 'Member pricing and additional <br /> 15% off',
      },
    ],
    [
      {
        title: 'Passport Photos',
        description:
          'Get your passport photos the easy way. Visit your local AAA service center where we can take your photos and print them right away.',
      },
      {
        description: 'Member pricing',
      },
      {
        description: 'Member pricing',
      },
      {
        description: 'One free set per year',
      },
    ],
    [
      {
        title: 'Hertz Gold® Membership Enrollment',
        description:
          'Get free enrollment with AAA membership and receive enrollment bonus points after completing first paid Gold rental. Use your points for free rentals, experiences and rewards.',
      },
      {
        description:
          'Free Hertz Gold membership with enrollment.<br>  One Free rental day with enrollment. After first Gold rental, receive enough Hertz Gold Rewards points for ONE FREE rental.',
      },
      {
        description:
          'Free Hertz Gold membership with enrollment.<br>  One Free rental day with enrollment. After first Gold rental, receive enough Hertz Gold Rewards points for ONE FREE rental.',
      },
      {
        description:
          'Free Hertz Gold membership with enrollment.<br>  One Free rental day with enrollment. After first Gold rental, receive enough Hertz Gold Rewards points for ONE FREE rental.',
      },
    ],
    [
      {
        title: 'Tire Service',
      },
      {
        description: 'Included',
      },
      {
        description: 'Included',
      },
      {
        description: 'Included',
      },
    ],
    [
      {
        title: 'AAA Approved Auto Repair',
        description:
          'Put your car in the hands of a AAA Approved Auto Repair facility that guarantees their work so you can get back on the road. Includes 10% member-only discounts on parts and labor at participating shops.',
      },
      {},
      {
        description: 'Included',
      },
      {
        description: 'Included',
      },
    ],
    [
      {
        title: 'AAA Discounts & Rewards®:',
        description:
          'The average member saves $150 a year when shopping, dining, buying entertainment discounts, traveling and more.',
      },
      { description: 'Included' },
      { description: 'Included' },
      { description: 'Included' },
    ],
    [
      {
        title: 'Mechanical First Aid',
        description: 'Get minor or temporary adjustments to get you back on the road.',
      },
      { description: 'Included' },
      { description: 'Included' },
      { description: 'Included' },
    ],
    [
      {
        title: 'Battery Service',
        description:
          'Car battery on-the-spot testing and jumpstart. Battery sales and free installation available in select area.',
      },
      { description: 'Included' },
      { description: 'Additional $20 off battery purchase' },
      { description: 'Additional $25 off battery purchase' },
    ],
    [
      {
        title: 'Free Maps, TourBooks and TripTik Routings',
        description:
          'Before you hit the road, put your membership to good use with our extensive travel planning tools.',
      },
      { description: 'Included' },
      { description: 'Included' },
      { description: 'Included' },
    ],
    [
      {
        title: 'Notary Service',
        description: 'Personal courtesy service provided for AAA members free of charge.',
      },
      { description: 'Included' },
      { description: 'Included' },
      { description: 'Included' },
    ],
    [
      {
        title: 'Hotel & Car Rental Discounts',
        description:
          "Get the best rates and exclusive member discounts. AAA hotels are backed by AAA's trademark Diamond ratings.",
      },
      { description: 'Included' },
      { description: 'Included' },
      { description: 'Included' },
    ],
    [
      {
        title: 'Cruise and Tour Benefits',
        description:
          'When it comes to travel, nobody does it better than AAA. We work with top-notch preferred providers to bring AAA members excellent service, great deals and exclusive member benefits.',
      },
      { description: 'Included' },
      { description: 'Included' },
      { description: 'Included' },
    ],
    [
      {
        title: 'Auto / Home Insurance Discounts',
        description:
          'Get quality insurance coverage that fits your lifestyle and budget. Plus, you may receive AAA member savings on your policy.',
      },
      { description: 'Included' },
      { description: 'Included' },
      { description: 'Included' },
    ],
    [
      {
        title: 'Home Lockout Service',
        description:
          'If you become locked out of your primary residence, you can receive up to $100 in locksmith services per year, per household to gain access to your home from the outside.',
      },
      {},
      {},
      { description: 'Included' },
    ],
    [
      {
        title: 'Free Car Rental',
        description:
          'When you need a replacement vehicle due to a mechanical breakdown that results in a tow, AAA can arrange a free Hertz rental car. (Arrangements must be made through AAA.)',
      },
      {},
      {},
      { description: 'One day free rental per household, per membership year.' },
    ],
  ];
};
