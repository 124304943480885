import { CardFormat } from '@aaa/interface-joinRenew-membership-membershipConnectSuite';

export const cardTypes = [
  {
    label: 'Digital',
    value: CardFormat.DIGITAL,
  },
  {
    label: 'Plastic',
    value: CardFormat.PLASTIC,
  },
  {
    label: 'Both',
    value: CardFormat.BOTH,
  },
];
